import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
// import SocialLinks from "../components/sociallinks";
import PortfolioList from "../components/list-portfolio";
// import BlogList from "../components/list-blog";
import {partners} from "../data/partners.js";
// import Partners from "/site/static/data/partners.js";
import Contact from "../components/contact";
import "../style/wall.less";

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winHeight: "100vh",
            index: 4,
            changeBannerId: null
        };
        this.changeBanner = this.changeBanner.bind(this);
    }

    createSVGElement(n, v) {
        n = document.createElementNS("http://www.w3.org/2000/svg", n);
        for (var p in v) n.setAttributeNS(null, p, v[p]);
        return n;
    }

    componentDidMount() {
        this.setWindowHeight();
        let _this = this;
        window.addEventListener("resize", function() {
            _this.setWindowHeight();
        });
        // let sWidth = this.svg.clientWidth,
        //     tText = this.svg.querySelector("text"),
        //     tWidth = tText.getBoundingClientRect().width;
        //
        // if (tWidth > sWidth) {
        //     let tInnerText = tText.innerHTML;
        //     if (tInnerText.split(" ").length > 1) {
        //         tText.innerHTML = "";
        //         tInnerText.split(" ").forEach(function(e, i) {
        //             let tSpan = _this.createSVGElement("tspan", {
        //                 dy: i === 0 ? "0em" : ".8em",
        //                 x: "50"
        //             });
        //             tSpan.innerHTML = e;
        //             tText.appendChild(tSpan);
        //         });
        //         setTimeout(function() {
        //             _this.svg.style.height =
        //                 tText.getBoundingClientRect().height + 70;
        //             _this.svg.style.margin = "15px auto";
        //         }, 250);
        //     } else {
        //         while (tWidth > sWidth) {
        //             let fontSize = parseInt(
        //                 window
        //                     .getComputedStyle(tText, null)
        //                     .getPropertyValue("font-size")
        //             );
        //             tText.style.fontSize = fontSize - 1 + "px";
        //             tWidth = tText.getBoundingClientRect().width;
        //         }
        //     }
        // }
        const changeBannerId = setInterval(this.changeBanner, 5000);
        this.setState({
            changeBannerId: changeBannerId
        })
    }

    componentWillUnmount() {
        if(this.state.changeBannerId){
            clearInterval(this.state.changeBannerId)
        }
    }

    changeBanner() {
        let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        // let isMobile = true;
        let index = this.state.index;
        const cycleImages = document.getElementsByClassName('image-cycle');
        let second = false;
        let third = false;
        let iterator = 1;
        if(!isMobile){
            second = (index + 1) % cycleImages.length;
            third = (index + 2) % cycleImages.length;
            iterator = 3;
        }
        [].forEach.call(cycleImages, async function(v, i) {
            if(i === index || i === second  || i === third){
                cycleImages[i].style.display = "block"
                cycleImages[i].classList.add("fade-in")
                // cycleImages[i].classList.remove("fade-out")
            } else {
                cycleImages[i].style.display = "none"
                cycleImages[i].classList.remove("fade-in")
                // cycleImages[i].classList.add("fade-out")
            }
        });
        index = (index + iterator) % cycleImages.length;
        this.setState({
            index: index
        })
    }

    setWindowHeight() {
        this.setState({
            winHeight: window.innerHeight
        });
    }

    render() {
        return (
            <Layout placeholder={false}>
                <SEO
                    lang="en"
                    title={this.props.data.site.siteMetadata.title}
                />
                {/*<div*/}
                {/*    className="wall"*/}
                {/*    // style={{ height: this.state.winHeight + "px" }}*/}
                {/*>*/}
                {/*    <div className="intro container">*/}
                {/*        <div className="main-title text-primary">*/}
                {/*            <svg*/}
                {/*                width="90%"*/}
                {/*                height="220px"*/}
                {/*                viewBox="0 0 100 100"*/}
                {/*                preserveAspectRatio="xMidYMid slice"*/}
                {/*                ref={c => (this.svg = c)}*/}
                {/*            >*/}
                {/*                {this.props.data.site.siteMetadata*/}
                {/*                    .capitalizeTitleOnHome*/}
                {/*                    ? this.props.data.site.siteMetadata.title.toUpperCase()*/}
                {/*                    : this.props.data.site.siteMetadata.title}*/}
                {/*                <pattern*/}
                {/*                    id="wallPattern"*/}
                {/*                    patternUnits="userSpaceOnUse"*/}
                {/*                    width="100"*/}
                {/*                    height="100"*/}
                {/*                >*/}
                {/*                    <rect*/}
                {/*                        x="0"*/}
                {/*                        y="0"*/}
                {/*                        className="fill-primary"*/}
                {/*                        width="100"*/}
                {/*                        height="100"*/}
                {/*                    />*/}
                {/*                    <image*/}
                {/*                        xlinkHref="/images/wall.jpg"*/}
                {/*                        height="100"*/}
                {/*                        width="100"*/}
                {/*                        y="0"*/}
                {/*                        preserveAspectRatio="none"*/}
                {/*                    />*/}
                {/*                </pattern>*/}
                {/*                <text*/}
                {/*                    // fill="url(#wallPattern)"*/}
                {/*                    fill="white"*/}
                {/*                    textAnchor="middle"*/}
                {/*                    x="50"*/}
                {/*                    y="50"*/}
                {/*                >*/}
                {/*                    {this.props.data.site.siteMetadata*/}
                {/*                        .capitalizeTitleOnHome*/}
                {/*                        ? this.props.data.site.siteMetadata.title.toUpperCase()*/}
                {/*                        : this.props.data.site.siteMetadata*/}
                {/*                              .title}*/}
                {/*                </text>*/}
                {/*            </svg>*/}
                {/*        </div>*/}
                {/*        <p className="tag-line text-secondary" style={{color:"white"}}>*/}
                {/*            {this.props.data.site.siteMetadata.introTag}*/}
                {/*        </p>*/}
                {/*        /!*<p className="caption text-tertiary">*!/*/}
                {/*        /!*    {this.props.data.site.siteMetadata.description}*!/*/}
                {/*        /!*</p>*!/*/}
                {/*        <a href="#contact" className="btn" style={{backgroundColor:"transparent", border:"1px solid white", color: "white"}}>*/}
                {/*            Contact Us*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="social-buttons">*/}
                {/*        /!*<SocialLinks />*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="partner-row">*/}
                {/*    <div className="partner-title">*/}
                {/*        <h4>About Us</h4>*/}
                {/*    </div>*/}
                {/*    <div className="partner-body">*/}
                {/*        <p>Sonoma Bespoke Beverages develops high quality private label wine, custom tailored to meet the needs of retailers, hotels and restaurants.</p>*/}
                {/*        <br/>*/}
                {/*        <p>We have best-in-class production facilities that allows us to be flexible and create high quality wine with advanced capabilities.  Our capabilities have allowed us to work with partners around the country, some existing customers already include:</p>*/}
                {/*        <br/>*/}
                {/*        <div className="partner-images">*/}
                {/*            {*/}
                {/*                partners.map((data, index) => {*/}
                {/*                    return (*/}
                {/*                        <a key={index} href={data.link} target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*                            <img src={data.img} />*/}
                {/*                        </a>*/}
                {/*                    );*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<PortfolioList />*/}
                {/*<BlogList />*/}
                {/*<div className="partner-row">*/}
                {/*    <div className="partner-title">*/}
                {/*        <h4>Our Partners</h4>*/}
                {/*    </div>*/}
                {/*    <div className="partner-images">*/}
                {/*        <a href="https://www.walmart.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/walmart_logo.jpeg" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.7-eleven.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/711_image.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.bevmo.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/bevmo_image.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.drinks.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/drinks_image.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://gianteagle.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/giant_eagle.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.lowesfoods.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/lowes_foods.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.traderjoes.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/trader_joes_image.png" />*/}
                {/*        </a>*/}
                {/*        <a href="https://www.wegmans.com/" target="_blank" className="image-cycle" rel="noopener noreferrer">*/}
                {/*            <img src="/images/wegmans_image.png" />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div style={{width: '100%', height: 20}} />
                <Contact />
            </Layout>
        );
    }
}

export default IndexPage;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                capitalizeTitleOnHome
                titleImage
                introTag
                description
                social {
                    name
                    url
                    icon
                }
            }
        }
    }
`;
